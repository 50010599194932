import styled from "styled-components";
import progressSpinner from "../images/spin-loader.svg";

const ProgressDialog = () => {
    return (
        <>
            <Container>
                <Content className="card shadow-xss rounded-xxl">
                    <img
                        src={progressSpinner}
                        style={{
                            height: "140px",
                            width: "140px",
                            align: "center",
                        }}
                    />
                </Content>
            </Container>
        </>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    height: 140px;
    width: 140px;
    background-color: white;
    overflow: initial;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 30%;
    margin: 0 auto;
`;

export default ProgressDialog;
